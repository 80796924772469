import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { getIsHomeOwner } from '../../util/userUtils';

const SectionMatchWithContractorMaybe = props => {
  const { currentUser } = props;

  const isHomeOwner = getIsHomeOwner(currentUser);

  if (!isHomeOwner) return null;

  return (
    <div className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.matchWithContractor" />
      </Heading>

      <p>
        <FormattedMessage id="ListingPage.matchWithContractorInfo"
          values={{ email: <a href="mailto:support@tacklpro.com">support@tacklpro.com</a> }} />
      </p>
    </div>
  );
};

export default SectionMatchWithContractorMaybe;
